import { StyledSvg } from "baseui/icon";
import React from "react";

export const ReactIcon = (props: React.ComponentProps<typeof StyledSvg>) => {
  return (
    <StyledSvg viewBox={`0 0 ${props.size ?? 32} ${props.size ?? 32}`} {...props}>
      <path
        d="M16 18.4741C17.3807 18.4741 18.5 17.3548 18.5 15.9741C18.5 14.5934 17.3807 13.4741 16 13.4741C14.6193 13.4741 13.5 14.5934 13.5 15.9741C13.5 17.3548 14.6193 18.4741 16 18.4741Z"
        fill={props.color ?? "#00D8FF"}
      />
      <path
        d="M16 21.706C12.9951 21.7787 9.9978 21.3737 7.12 20.506C5.79062 20.0846 4.55073 19.4208 3.463 18.548C3.04793 18.2499 2.70227 17.8655 2.44974 17.4212C2.19721 16.9769 2.04379 16.4832 2 15.974C2 14.321 3.816 12.701 6.858 11.641C9.81273 10.7018 12.8999 10.2466 16 10.293C19.0587 10.2503 22.1045 10.6973 25.022 11.617C26.3023 12.0252 27.5001 12.6569 28.56 13.483C28.968 13.7673 29.3084 14.1377 29.5573 14.5682C29.8061 14.9987 29.9573 15.4786 30 15.974C30 17.692 27.97 19.433 24.7 20.515C21.8779 21.3544 18.9438 21.7561 16 21.706ZM16 11.489C13.0341 11.4494 10.0808 11.8821 7.251 12.771C4.451 13.748 3.196 15.084 3.196 15.971C3.196 16.899 4.545 18.358 7.507 19.371C10.2601 20.1969 13.1266 20.5813 16 20.51C18.8164 20.5604 21.6239 20.1793 24.325 19.38C27.4 18.361 28.8 16.9 28.8 15.974C28.7495 15.6562 28.6337 15.3524 28.4599 15.0816C28.2861 14.8109 28.058 14.5791 27.79 14.401C26.8418 13.6682 25.7717 13.1083 24.629 12.747C21.838 11.8708 18.925 11.4461 16 11.489Z"
        fill={props.color ?? "#00D8FF"}
      />
      <path
        d="M10.3201 28.443C9.8536 28.4537 9.3926 28.3405 8.9841 28.115C7.5521 27.289 7.0561 24.907 7.6571 21.742C8.31974 18.7131 9.4679 15.8113 11.0571 13.149C12.5478 10.4781 14.4563 8.06289 16.7101 5.99497C17.7033 5.08968 18.849 4.36752 20.0941 3.86197C20.5445 3.65091 21.0357 3.5415 21.5331 3.5415C22.0305 3.5415 22.5217 3.65091 22.9721 3.86197C24.4611 4.71997 24.9541 7.34797 24.2591 10.721C23.576 13.5855 22.4577 16.3284 20.9431 18.854C19.5048 21.4933 17.6565 23.8875 15.4671 25.947C14.4379 26.8878 13.2434 27.6299 11.9441 28.136C11.4239 28.329 10.8749 28.4328 10.3201 28.443ZM12.0931 13.743C10.5772 16.2923 9.47676 19.0667 8.8331 21.962C8.2801 24.877 8.8111 26.63 9.5831 27.076C10.3831 27.539 12.3251 27.1 14.6831 25.04C16.7731 23.0669 18.5373 20.7752 19.9101 18.25C21.361 15.8355 22.4338 13.2131 23.0911 10.474C23.7451 7.29897 23.1801 5.35497 22.3781 4.89297C22.0775 4.7778 21.7563 4.72624 21.4348 4.74156C21.1132 4.75688 20.7984 4.83874 20.5101 4.98197C19.4018 5.43932 18.3828 6.0886 17.5001 6.89997C15.3468 8.88076 13.5238 11.1929 12.1001 13.749L12.0931 13.743Z"
        fill={props.color ?? "#00D8FF"}
      />
      <path
        d="M21.677 28.456C20.322 28.456 18.601 27.636 16.809 26.095C14.5157 24.0083 12.575 21.5644 11.062 18.858C9.49278 16.2323 8.35371 13.3725 7.68805 10.387C7.39935 9.07347 7.34586 7.71917 7.53005 6.38698C7.57263 5.89279 7.72311 5.41395 7.97088 4.98424C8.21865 4.55453 8.55767 4.18441 8.96404 3.89998C10.451 3.03898 12.974 3.92398 15.549 6.20998C17.6896 8.23216 19.5074 10.5707 20.939 13.144C22.5073 15.7083 23.6583 18.5055 24.349 21.431C24.6502 22.7927 24.6966 24.1984 24.486 25.577C24.4359 26.0854 24.2765 26.5769 24.0186 27.018C23.7607 27.459 23.4106 27.839 22.992 28.132C22.5902 28.3547 22.1364 28.4665 21.677 28.456ZM12.097 18.256C13.5485 20.8424 15.4023 23.1813 17.589 25.185C19.838 27.12 21.622 27.536 22.389 27.085C23.189 26.62 23.779 24.722 23.171 21.651C22.5049 18.8579 21.4009 16.1879 19.9 13.74C18.5339 11.2771 16.7988 9.03784 14.755 7.09998C12.331 4.94798 10.365 4.46698 9.56404 4.93098C9.31439 5.13386 9.10951 5.3863 8.96235 5.67237C8.81519 5.95843 8.72894 6.2719 8.70904 6.59298C8.55005 7.78106 8.60184 8.98788 8.86205 10.158C9.50235 13.0131 10.5948 15.7476 12.098 18.258L12.097 18.256Z"
        fill={props.color ?? "#00D8FF"}
      />
    </StyledSvg>
  );
};
